.title_div {
    position: relative;
    width: 100%;
    height: 8.43vh;
    line-height: 8.43vh;
    text-align: center;
    font-size: 32px;
    padding-top: 10px;
    background: url("../../asset/home/page_header_img.png") left top/cover no-repeat;
    background-size: 100% 8.43vh;
}

.title_content {
    font-family: FZCSJW--GB1-0;
    color: white;
    font-weight: 400;
    background: linear-gradient(180deg, #CEDCF1 0%, #62ADF4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.back_img {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    line-height: normal;
    width: 55px;
    height: 28px;
    right: 60px;
    top: 30px;
    cursor: pointer;
    font-size: 14px;
    color: #FFFFFF;
}

.back_img img {
    width: 20px;
}
