.workorder_map_chart_page {
    width: calc(100vw - 950px);
    height: 77vh;
    position: relative;
    padding: 24px;
    /*background-color: red;*/
    margin: 16px 16px 0 16px;
}

.workorder_map_chart_title_box {
    position: absolute;
    display: flex;
}

.workorder_map_chart_title {
    font-weight: bold;
    font-size: 48px;
    color: #00BDFF;
}

.workorder_chart_label {
    display: flex;
    flex-direction: column;
}

.workorder_chart_now_value {
    color: #CED2FE;
    font-size: 14px;
}

.workorder_chart_data_value {
    font-size: 30px;
    font-weight: bold;
    color: #CED2FE;
}

.map_back {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 12px;
    color: #FFFFFF;
    cursor: pointer;
}

.map_back_icon {
    width: 18px;
    height: 14px;
    z-index: 999999;
}
