.storehouse_dialog_box {
    width: 460px;
    height: 46.57407vh;
    background: url("../../../asset/storehouse/storehouse_map_dialog_bg.png") left top/cover no-repeat;
    background-size: 460px 46.57407vh;
    padding: 24px 16px;
}

.storehouse_dialog_title {
    color: white;
    font-size: 24px;
    font-weight: bold;
    padding: 8px 0 8px 8px;
}

.storehouse_dialog_item {
    font-size: 1.48148vh;
    color: #FFFFFF;
    padding: 1vh 0;
}

.storehouse_dialog_img {
    width: 417px;
    height: 16.75926vh;
    /*margin-top: 18px;*/
    margin-top: 4px;
}

.amap-info-close {
    top: 20px;
}
