.left_content_box {
    margin-top: 16px;
}

.system_info {
    padding: 24px;
    width: 452px;
    height: 41.57407vh;
    background: url("../../../../../asset/area/area_system_bg.png") left top/cover no-repeat;
    background-size: 452px 41.57407vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.system_item {
    padding: 10px 0;
    border-bottom: 1px dashed #00BDFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.system_item_label {
    font-size: 16px;
    color: #CED2FE;
}

.system_item_icon {
    width: 28px;
    height: 28px;
    margin-right: 8px;
    transform: translateY(-2px);
}

.system_item_value {
    font-size: 24px;
    color: #00FFE1;
    font-weight: bold;
}


/*本地工程师*/
.system_engineer {
    width: 452px;
    height: 34.44444vh;
    padding: 24px;
    background: url("../../../../../asset/area/area_engineer_bg.png") left top/cover no-repeat;
    background-size: 452px 34.44444vh;
    margin-top: 16px;
}

.system_engineer_title {
    color: #00BDFF;
    font-size: 18px;
    font-weight: bold;
}

.system_engineer_content {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 16px;
}

.system_engineer_item {
    display: flex;
    padding: 18px 10px;
    border-bottom: 1px dashed #CED2FE;
    cursor: pointer;
}

.system_engineer_avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 8px;
}

.system_engineer_info {

}

.system_engineer_name_state {

}

.system_engineer_name {
    font-size: 16px;
    color: #FFFFFF;
    font-weight: bold;
}

.system_engineer_state {
    display: inline-block;
    font-size: 10px;
    color: #00FFE1;
    transform: scale(0.833);
    margin-left: 4px;
}

.system_engineer_department {
    display: flex;
    align-items: center;
    margin-top: 4px;
}

.system_engineer_department_label {
    display: inline-block;
    color: #CED2FE;
    font-size: 12px;
    flex: 1;
}

.system_engineer_department_value {
    display: inline-block;
    color: #CED2FE;
    font-size: 12px;
    max-width: 230px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
