.workorder_numerical_box {
    display: flex;
    justify-content: space-between;
}

.numerical_item {
    width: 249px;
    height: 9.25926vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.item_value {
    font-size: 30px;
}

.item_label {
    color: #E6E6E6;
    font-size: 14px;
}

.numerical_workorder_total {
    background: url("../../../../../asset/workorder/workorder_total_num_bg.png") center center/cover no-repeat;
}

.numerical_workorder_total_value {
    background: linear-gradient(180deg, #ADE5FF 0%, #17C2FE 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.numerical_workorder_dispatch {
    background: url("../../../../../asset/workorder/workorder_dispatch_bg.png") center center/cover no-repeat;
}

.numerical_workorder_dispatch_value {
    background: linear-gradient(180deg, #ADE5FF 0%, #00FFE1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.numerical_workorder_receive {
    background: url("../../../../../asset/workorder/workorder_receive_bg.png") center center/cover no-repeat;
}

.numerical_workorder_receive_value {
    background: linear-gradient(180deg, #FBCAFD 0%, #F955FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.numerical_workorder_finish {
    background: url("../../../../../asset/workorder/workorder_finish_bg.png") center center/cover no-repeat;
}

.numerical_workorder_finish_value {
    background: linear-gradient(180deg, #ADC2FF 0%, #39A0FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.numerical_workorder_already_finish {
    background: url("../../../../../asset/workorder/workoredr_already_finish_bg.png") center center/cover no-repeat;
}

.numerical_workorder_already_finish_value {
    background: linear-gradient(180deg, #ADFFCC 0%, #39FF71 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.numerical_workorder_evaluate {
    background: url("../../../../../asset/workorder/workorder_evaluate_bg.png") center center/cover no-repeat;
}

.numerical_workorder_evaluate_value {
    background: linear-gradient(180deg, #FCFDDA 0%, #FFEF39 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.numerical_workorder_timeout {
    background: url("../../../../../asset/workorder/workorder_tiomout_bg.png") center center/cover no-repeat;
}

.numerical_workorder_timeout_value {
    background: linear-gradient(180deg, #FFCDCD 0%, #FF0000 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
