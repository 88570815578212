.workorder_right_content {
    margin-top: 16px;
}

.workorder_chart_box {
    padding: 27px 13px;
    width: 452px;
}

.workorder_repair_path_box {
    height: 23.14815vh;
    background: url("../../../../../asset/workorder/workorder_repair_route.png") left top/cover no-repeat;
    background-size: 452px 100%;
}

.workorder_chart_title {
    font-size: 16px;
    color: #00BDFF;
    font-weight: bold;
}

.workorder_chart_size {
    width: 100%;
    height: 100%;
}

.workorder_finish_time {
    height: 25vh;
    background: url("../../../../../asset/workorder/workorder_finish_time_bg.png") left top/cover no-repeat;
    background-size: 452px 100%;
    margin-top: 16px;
}

.workorder_trend {
    height: 26.20370vh;
    background: url("../../../../../asset/workorder/workorder_trend.png") left top/cover no-repeat;
    background-size: 452px 100%;
    margin-top: 16px;
}
