.fevice_type_scores_item{
    height: calc(100vh - 25.5vh);
    width: 95%;
    margin: 0 auto;
    overflow: hidden;
}
.grade_fault_box{
    height: calc(68vh - 36vh);
    padding-bottom: 20px;
}
.text_num_box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.text_echarts{
    width: 100%;
}
.device_response_box{
    padding-top: 20px;

}

.Dialogzc{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999999999;
}

.grade,.fault{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: calc((68vh - 36vh)/2);
}
.grade img,.fault img{
    width: 81px;
    height: 65px;
    margin-right: 20px;
}
.text_num{}
.titleline{

}
.titleline{
    height: 24px;
    line-height: 24px;
    padding-left: 10px;
    background: #01165e9c;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    position: relative;
}
.titleline::before{
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    width: 3px;
    height: 16px;
    background: #00F6FF;
    display: block;
}
.titleline::after{
    content: "";
    position: absolute;
    top: 5px;
    right: 0;
    width: 3px;
    height: 16px;
    background: #00F6FF;
    display: block;
}



.text_num_pie_echarts_box{
    width: 100%;
    height: calc((100vh - 510px)/3);
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.text_num_pie_echarts_box .text_num{
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.text_num_pie_echarts_box .pie_echarts{
    width: 60%;
    height: 100%;
    z-index: 99;
}

.titTag{
    background: url("../../../../../asset/biaoqianico.png") no-repeat left center;
    color: #FFFFFF;
    font-size: 14px;
    height: 25px;
    line-height: 25px;
    padding-left: 5px;
}
.titNum{
    font-weight: 400;
    font-size: 48px;
    color: #FFB400;
    height: 85px;
    line-height: 105px;
}
.tittip{
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

