.device_map_page {
    width: 100%;
    height: 100vh;
    background-color: var(--themeBgColor);
}

.map {
    position: relative;
    width: 100%;
    height: 90.8vh;
    margin: 4px;
}

.device_map_box {
    display: flex;
    background-color: var(--themeBgColor);
}

.device_map_sidebar {
    width: 420px;
    height: 90.8vh;
    padding: 8px;
    /*overflow-x: hidden;*/
}

.device_question_manufacturer {
    position: absolute;
    right: 10px;
    top: 16px;
    z-index: 10;
    color: white;
    font-size: 12px;
    display: flex;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.3);

}

.device_question_manufacturer span {
    display: flex;
    align-items: center;
    margin-right: 24px;
    cursor: pointer;
}

.device_question_manufacturer span img {
    width: 19px;
    margin-right: 4px;
    transform: translateY(-1px);
}
