/*个人完成指标数*/
.person_work_finish_situation_view {
    margin-top: 26px;
    position: relative;
    width: 1358px;
    height: 27.98148vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.person_input_device_num {
    width: 190px;
    height: 190px;
    background: url("../../../../../../asset/engineer/detail/target_icon.png");
    background-size: 1200px 190px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.person_replace_label_num {
    width: 190px;
    height: 190px;
    background: url("../../../../../../asset/engineer/detail/target_icon.png") -202px;
    background-size: 1200px 190px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.person_knowledge_num {
    width: 190px;
    height: 190px;
    background: url("../../../../../../asset/engineer/detail/target_icon.png") -404px;
    background-size: 1200px 190px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.person_handle_related_device_workorder {
    width: 190px;
    height: 190px;
    background: url("../../../../../../asset/engineer/detail/target_icon.png") -606px;
    background-size: 1200px 190px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.person_related_device_workorder_num {
    width: 190px;
    height: 190px;
    background: url("../../../../../../asset/engineer/detail/target_icon.png") -808px;
    background-size: 1200px 190px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.person_migrate_device_num {
    width: 190px;
    height: 190px;
    background: url("../../../../../../asset/engineer/detail/target_icon.png") -1010px;
    background-size: 1200px 190px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.person_input_device_num_value {
    font-size: 35px;
    color: #0DFFFA;
}

.person_input_device_num_label {
    font-size: 11px;
    transform: scale(0.8);
    color: #0DFFFA;
    margin-top: 8px;
}

.person_replace_label_num_value {
    font-size: 35px;
    color: #FFBE44;
}

.person_replace_label_num_label {
    font-size: 11px;
    transform: scale(0.8);
    color: #FFBE44;
    margin-top: 8px;
}

.person_knowledge_num_value {
    font-size: 35px;
    color: #216CFF;
}

.person_knowledge_num_label {
    font-size: 11px;
    transform: scale(0.8);
    color: #216CFF;
    margin-top: 8px;
}

.person_handle_related_device_workorder_value {
    font-size: 35px;
    color: #A086FE;
}

.person_handle_related_device_workorder_label {
    font-size: 11px;
    transform: scale(0.8);
    color: #A086FE;
    margin-top: 8px;
}

.person_related_device_workorder_num_value {
    font-size: 30px;
    color: #0DFFFA;
}

.person_related_device_workorder_num_label {
    font-size: 11px;
    transform: scale(0.8);
    color: #0DFFFA;
    margin-top: 8px;
}

.person_migrate_device_num_value {
    font-size: 35px;
    color: #FF4545;
}

.person_migrate_device_num_label {
    font-size: 11px;
    transform: scale(0.8);
    color: #FF4545;
    margin-top: 8px;
}

/*容器边角*/
.card_top_left_corner {
    z-index: 999999;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-bottom-color: transparent;
    border-right-color: transparent;
}

.card_top_right_corner {
    z-index: 999999;
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-bottom-color: transparent;
    border-left-color: transparent;
}

.card_bottom_left_corner {
    z-index: 999999;
    position: absolute;
    bottom: 0;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-top-color: transparent;
    border-right-color: transparent;
}

.card_bottom_right_corner {
    z-index: 999999;
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-top-color: transparent;
    border-left-color: transparent;
}
