.sidebar_list_box {
    width: 400px;
    height: 93%;
    margin-top: 16px;
    background: url("../../../../../asset/device/device_map_sidebar_bg.png") left top/cover no-repeat;
    background-size: 100% 100%;
    padding: 24px 16px 16px 16px;
}

.list_btn_box {
    display: flex;
    justify-content: space-between;
}

.device_list_btn_box {
    background: url("../../../../../asset/device/device_list_btn_bg.png") left top/cover no-repeat;
    background-size: 126px 3.79630vh;
    width: 126px;
    height: 3.79630vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
}

.device_list_btn {
    display: inline-block;
    font-size: 14px;
    background: linear-gradient(180deg, #CEDCF1 0%, #51FCF9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.device_question_btn_box {
    background: url("../../../../../asset/device/device_question_btn_bg.png") left top/cover no-repeat;
    background-size: 126px 3.79630vh;
    width: 126px;
    height: 3.79630vh;
    border-radius: 8px;
    /*margin-left: 16px;*/
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.device_refresh_btn {
    width: 85px;
    height: 3.75vh;
    /*margin-left: 8px;*/
    cursor: pointer;
}

.device_question_btn {
    display: inline-block;
    font-size: 14px;
    background: linear-gradient(180deg, #CEDCF1 0%, #51FCF9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/*设备列表*/
.device_list_box {
    max-height: 75vh;
    overflow-y: scroll;
}

.device_list_item {
    background-color: #0A1561;
    height: 14vh;
    width: 100%;
    padding: 16px 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
    border-radius: 8px;
    cursor: pointer;
}

.device_info_box {
    padding-bottom: 20px;
    border-bottom: 1px dashed #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.device_department_client {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.device_list_item_style {
    display: inline-block;
    color: #FFFFFF;
    font-size: 12px;
    width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    /*margin-top: 16px;*/
}

.device_name_repair {
    display: flex;
    justify-content: space-between;
}

.device_type {
}

.device_detail {
    color: #51FCF9;
    font-size: 12px;
    text-align: center;
    width: 100%;
    cursor: pointer;
    padding: 10px 0;
    border-top: 1px dashed #FFFFFF;
}

/*问题监控*/
.device_question_box {

}

.device_question_descriptions {
    width: 100% !important;
    color: #FFFFFF;
    font-size: 12px;
    line-height: 24px;
}

.device_question_item_style {
    width: 49%;
    margin-top: 16px;
}

.device_question_list_item {
    height: auto;
    padding: 0 12px 16px 12px;
    display: flex;
    justify-content: space-around;
    cursor: pointer;
}
.device_question_Pagination{
    background: #0a1561;
    padding: 5px 0;
    margin: 5px 0;
    border-radius: 6px;
    text-align: center;
}
.device_question_Pagination a{
    color: #7c85c6 !important;
}
.device_question_Pagination svg{
    color: #ffffff !important;
}
.device_question_Pagination .ant-pagination-item-active{
    background-color: #0a1561 !important;
}
.device_question_Pagination .ant-pagination-item-active a{
    color: #0a1561 !important;
}