.map {
    position: relative;
    width: 100%;
    height: 90.8vh;
    margin: 4px 0 4px 4px;
}

.engineer_map_box {
    display: flex;
    background-color: var(--themeBgColor);
}

.sidebar {
    position: relative;
    background-color: var(--themeBgColor);
    width: 481px;
    padding: 16px;
}

/*右边头部*/
.sidebar_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon_and_department_info {
    display: flex;
    align-items: center;
}

.department_img {
    width: 60px;
    height: 60px;
    margin-right: 10px;
}

.department_info {
    display: flex;
    flex-direction: column;
}

.department_name {
    font-size: 18px;
    color: #FFFFFF;
}

.department_people_num {
    color: #FFFFFF;
    font-size: 14px;
    margin-top: 10px;
}

.sidebar_more {
    width: 7px;
    height: 7px;
    border: 6px solid transparent;
    border-left-color: #FFFFFF;
    cursor: pointer;
}

/*右边头部结束*/

/*工程师列表开始*/
.engineer_list_box {
    width: 100%;
    padding: 30px 8px 8px 12px;
    margin-top: 16px;
    height: 92%;
    color: #FFFFFF;
    overflow: hidden;
    background: url("../../../../../asset/engineer/sidebar_bg.png") left top/cover;
}

.engineer_list_title {
    width: 100%;
    font-weight: bold;
    color: #FFFFFF;
    padding-bottom: 24px;
    border-bottom: 1px solid #00FFD8;
}

.engineer_list_title_img {
    width: 22px;
    height: 22px;
    transform: translateY(-2px);
    margin-right: 8px;
}

.working_img {
    width: 22px;
    margin-right: 8px;
    transform: translateY(-2px);
}

.panel_header_title {
    color: #CED2FE;
    font-size: 16px;
}

.engineer_list_box :global :where(.css-dev-only-do-not-override-1me4733).ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: #FFFFFF;
    padding-left: 0;
    padding-right: 0;
}

.engineer_list_box :global :where(.css-dev-only-do-not-override-1me4733).ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
}

.limit_box {
    max-height: 450px;
    overflow-y: scroll;
}

.engineer_item {
    display: flex;
    align-items: center;
    padding: 12px 0;
    overflow: hidden;
    cursor: pointer;
}

.engineer_item_avatar_img {
    width: 26px;
    height: 26px;
    transform: translateY(-1px);
}

.engineer_item_name {
    width: 60px;
    font-size: 14px;
    color: #FFFFFF;
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.engineer_item_department {
    display: inline-block;
    width: 170px;
    font-size: 14px;
    color: #FFFFFF;
    margin-left: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list_item_engineer_num {
    font-size: 16px;
    color: #CED2FE;
    margin-right: 10px;
}

/*列表结束*/

/*工程师排行开始*/
.engineer_order {
    color: #FFFFFF;
    font-size: 16px;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 99;
    cursor: pointer;
}

.engineer_order_img {
    width: 20px;
    height: 20px;
    transform: translateY(-2px);
    margin-right: 8px;
}

/*地图边角*/
.card_top_left_corner {
    z-index: 999999;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-bottom-color: transparent;
    border-right-color: transparent;
}

.card_top_right_corner {
    z-index: 999999;
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-bottom-color: transparent;
    border-left-color: transparent;
}

.card_bottom_left_corner {
    z-index: 999999;
    position: absolute;
    bottom: 0;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-top-color: transparent;
    border-right-color: transparent;
}

.card_bottom_right_corner {
    z-index: 999999;
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-top-color: transparent;
    border-left-color: transparent;
}

