.department_detail_page {
    width: 100%;
    height: 100vh;
    background: var(--themeBgColor);
}

.department_detail_content {
    width: 100%;
    height: calc(100vh - 102px);
    padding: 10px 24px;
}

.department_info_box {
    width: 100%;
    height: 16vh;
    padding: 30px 24px;
    background: url("../../../../asset/department/detail/department_detail_title_bg.png") var(--themeBgColor) left top/cover no-repeat;
    background-size: 100% 16vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.department_index_box {
    display: flex;
    justify-content: space-between;
}

.department_logo_name {
    color: #FFFFFF;
}

.department_logo {
    width: 30px;
    height: 30px;
    transform: translateY(-3px);
}

.department_name {
    font-size: 24px;
    margin-left: 16px;
}

.department_index_value {
    display: inline-block;
    color: #FFFFFF;
    font-size: 16px;
    max-width: 600px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.department_index_icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    transform: translateY(-1px);
}

/* 工程师列表项 */
.department_engineer_box {
    width: 100%;
    height: 71vh;
    background: url("../../../../asset/department/detail/department_detail_content_bg.png") var(--themeBgColor) left top/cover no-repeat;
    background-size: 100% 71vh;
    overflow-y: scroll;
    margin-top: 24px;
    padding: 30px 43px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.department_engineer_item {
    position: relative;
    width: 425px;
    height: 16.50000vh;
    padding: 0 24px;
    display: flex;
    align-items: center;
    border: 1px solid var(--borderLineColor);
    margin-top: 24px;
    cursor: pointer;
}

.department_engineer_avatar {
    width: 104px;
    height: 11.29630vh;
}

.department_engineer_name_work {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.department_engineer_name {
    display: inline-block;
    max-width: 250px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 27px;
    font-weight: bold;
    color: #FFFFFF;
}

.department_engineer_total_score {
    font-size: 18px;
    color: #00FFE1;
    margin-top: 10px;
}

.department_engineer_workorder {
    font-size: 14px;
    color: #FFFFFF;
    margin-top: 10px;
}

.department_engineer_phone {
    font-size: 14px;
    color: #FFFFFF;
    margin-top: 10px;
}


.fill_box {
    width: 425px;
    height: 16.50000vh;
    padding: 0 24px;
    margin-top: 24px;
}
