.engineer_order_div {
    position: absolute;
    color: white;
    z-index: 999999;
    top: 60px;
    right: 20px;
    width: 452px;
    height: 372px;
    background: url("../../../../../asset/engineer/engineer_order_bg.png") right top/cover no-repeat;
    padding: 27px 12px;
}

.engineer_order_title {
    font-size: 18px;
    color: #FFFFFF;
    font-weight: bold;
}

.engineer_order_main {
    width: 100%;
    height: 100%;
}

/*机构列表开始*/
.department_list_view {
    position: absolute;
    width: 92%;
    /*width: 450px;*/
    height: 92%;
    background-color: #0A103A;
    border-radius: 8px;
    top: 60px;
    right: 10px;
    box-shadow: 1px 1px 15px 1px #047BE6, -1px -1px 15px 1px #047BE6;
    padding: 0 20px;
    overflow-y: scroll;
    transition: all 0.4s ease-in-out;
}

.department_list_view:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 7px;
    border-width: 0 5px 8px 0;
    border-style: none solid solid none;
    border-color: transparent transparent #047BE6 red;
    top: -7px;
    right: 1px;
}

.department_list_item {
    display: flex;
    justify-content: start;
    align-items: center;
    border-bottom: 1px solid #06315C;
    padding: 16px 0;
    cursor: pointer;
}

.department_list_item_logo {
    width: 26px;
}

.department_list_item_name {
    display: inline-block;
    color: #CED2FE;
    font-size: 16px;
    margin-left: 8px;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
