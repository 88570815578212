/*个人信息*/
.engineer_info_view {
    position: relative;
    width: 480px;
    height: 18.74074vh;
    border: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: start;
    padding: 0 20px;
    align-items: center;
    margin-top: 4px;
}

.engineer_info_right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.engineer_avatar {
    width: 129px;
    height: 15.64815vh;
    margin-right: 31px;
    border-radius: 8px;
}

.engineer_name_view {
    color: #CED2FE;
    font-size: 12px;
}

.engineer_name {
    display: inline-block;
    max-width: 150px;
    color: #FFFFFF;
    font-size: 30px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 12px;
}

.work_state_icon {
    width: 16px;
    margin-right: 4px;
}

.engineer_department_view {
    font-size: 16px;
    color: #FFFFFF;
    max-width: 260px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.engineer_department_logo {
    width: 20px;
    margin-right: 12px;
}

.engineer_phone_view {
    color: #A0A4CA;
    font-size: 16px;
}

.engineer_address_view {
    color: #A0A4CA;
    font-size: 16px;
    max-width: 260px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.engineer_address_icon {
    width: 19px;
    position: absolute;
    top: 16px;
    right: 16px;
}

/*个人信息结束*/

/*工程师积分*/
.engineer_total_score {
    position: relative;
    width: 480px;
    height: 35.87037vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    /*align-items: center;*/
    margin-top: 24px;
    padding-top: 14px;
    padding-bottom: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.engineer_order_and_score {
    display: flex;
    justify-content: center;
    align-items: center;
}

.engineer_score_order {
    width: 190px;
    height: 18.98148vh;
    background: url("../../../../../../asset/engineer/detail/engineer_total_score.png") no-repeat;
    background-size: 190px 190px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.engineer_score {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 16px;
}

.engineer_score_detail {
    color: #FFFFFF;
    font-size: 33px;
}

.engineer_order_detail {
    font-size: 10px;
    transform: scale(0.83);
    color: #FFFFFF;
}

.engineer_score_title {
    font-size: 30px;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 16px;
}

.engineer_finish_workorder_num {
    color: #24D7F1;
    font-size: 16px;
}

.score_medal_descriptions {
    display: flex;
    margin-top: 20px;
}

.integral_description_box {
    position: relative;
}

.score_descriptions, .medal_descriptions {
    background-color: #EFF2FD;
    color: #5B87FA;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 6px;
    cursor: pointer;
}

.medal_descriptions {
    margin-left: 8px;
    background-color: #EFEFFD;
    color: #8B80F6;
}

.engineer_medal_view {
    width: 100%;
    height: 9.25926vh;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 8px;
    padding-left: 14px;
    /*transform: translateY(-10px);*/
}

.device_master {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 6px 8px;
    background-color: #FEFDEC;
    color: #CC4700;
    font-size: 10px;
    transform: scale(0.88);
}

.repair_master {
    border-radius: 8px;
    padding: 6px 16px 6px 6px;
    background-color: #F1F9FF;
    color: #465BA6;
    font-size: 10px;
    transform: scale(0.82);
}

.on_time {
    display: inline-block;
    width: 31%;
    margin-top: 8px;
    margin-left: 10px;
    border-radius: 8px;
    /*padding: 6px 8px;*/
    /*padding: 6px 16px 6px 6px;*/
    /*background-color: #EFEFFD;*/
    color: #491ED5;
    font-size: 10px;
    /*transform: scale(0.9);*/
}

.engineer_medal_item {
    width: 31%;
    height: 3.4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    border-radius: 8px;
}

/*金*/
.engineer_medal_gold {
    background: #FEFDEC;
    font-size: 12px;
    color: #FF9600;
}

/*银*/
.engineer_medal_silver {
    background: #F1F9FF;
    font-size: 12px;
    color: #465BA6;
}

/*铜*/
.engineer_medal_copper {
    background: #FEF6EC;
    font-size: 12px;
    color: #CC4700;
}

.engineer_medal_icon {
    width: 21px;
    height: 2.31481vh;
    margin-right: 4px;
}

/*工程师积分结束*/

/*雷达图*/
.engineer_radar_chart_view {
    position: relative;
    width: 480px;
    height: 28.09259vh;
    margin-top: 24px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.engineer_radar_chart {
    width: 480px;
    height: 28.09259vh;
}

.department_left_chart_empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/*容器边角*/
.card_top_left_corner {
    z-index: 999999;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-bottom-color: transparent;
    border-right-color: transparent;
}

.card_top_right_corner {
    z-index: 999999;
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-bottom-color: transparent;
    border-left-color: transparent;
}

.card_bottom_left_corner {
    z-index: 999999;
    position: absolute;
    bottom: 0;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-top-color: transparent;
    border-right-color: transparent;
}

.card_bottom_right_corner {
    z-index: 999999;
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-top-color: transparent;
    border-left-color: transparent;
}

