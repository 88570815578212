.case_echarts_box{
    width: 100%;
    height: calc(100vh - 303px);
    margin: 20px auto 0 auto;
}
.case_echarts_box .title{
    height: 24px;
    line-height: 24px;
    padding-left: 10px;
    background: #01165e9c;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    position: relative;
}
.case_echarts_box .title::before{
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    width: 3px;
    height: 16px;
    background: #00F6FF;
    display: block;
}
.case_echarts_box .title::after{
    content: "";
    position: absolute;
    top: 5px;
    right: 0;
    width: 3px;
    height: 16px;
    background: #00F6FF;
    display: block;
}

.case_echarts_box .text_num_pie_echarts_box{
    width: 100%;
    height: calc((100vh - 510px)/3);
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.case_echarts_box .text_num_pie_echarts_box .text_num{
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.case_echarts_box .text_num_pie_echarts_box .pie_echarts{
    width: 70%;
    height: 100%;
    z-index: 99;

}
.case_echarts_box .text_num_pie_echarts_box .no_pie_echarts{
    width: 70%;
    height: 100%;
    z-index: 99;
    background: url("../../../../../asset/nocopy.png") no-repeat 50% center;
    background-size: 30% auto;

}

.titTag{
    background: url("../../../../../asset/biaoqianico.png") no-repeat right center;
    color: #FFFFFF;
    font-size: 14px;
    height: 25px;
    line-height: 25px;
    padding: 0 10px 0 5px;
}
.titNum{
    font-weight: 400;
    font-size: 48px;
    color: #FFB400;
    height: 1.5vh;
    line-height: 2vh;
}
.tittip{
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}