.modal_mask_layer {
    width: 100%;
    height: 100vh;
    background-color: var(--maskLayerBg);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.integral_box {
    z-index: 99999999;
    width: 452px;
    height: 34.44444vh;
    background: url("../../../../asset/engineer/integral_modal_bg.png") top left/cover no-repeat;
    background-size: 452px 34.44444vh;
    position: absolute;
    top: 3.44074vh;
}

.integral_close {
    position: relative;
    width: 100%;
    height: 10%;
}

.close {
    position: absolute;
    z-index: 999999999;
    width: 25px;
    top: 16px;
    right: 10px;
    cursor: pointer;
}


.integral_title {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 20px;
}


.integral_content {
    width: 100%;
    height: 90%;
    padding-left: 13px;
    overflow-y: scroll;
}

.integral_content span {
    display: inline-block;
    text-indent: 1rem;
    color: #BCC1FD;
    font-size: 16px;
    line-height: 28px;
}
