.storehouse_map_page {
    width: 100%;
    height: 100vh;
    background-color: var(--themeBgColor);
}

.storehouse_map_box {
    display: flex;
    background-color: var(--themeBgColor);
}

.map {
    position: relative;
    width: 100%;
    height: 90.8vh;
    margin: 4px;
}


.storehouse_map_sidebar {
    width: 600px;
    height: 90.8vh;
}


/*仓库列表*/
.storehouse_list_box {
    position: relative;
    width: 100%;
    height: 30.75926vh;
    padding: 20px 10px;
    border: 1px solid var(--borderLineColor);
    margin-bottom: 16px;
}

.storehouse_list_title {
    display: inline-block;
    color: #FFFFFF;
    font-size: 14px;
    padding: 8px 17px;
    background: linear-gradient(to bottom, #25F9D7, #2981E3);
    border-radius: 4px;
}

.storehouse_list_item_box {
    height: 95%;
    overflow-y: scroll;
}

.storehouse_list_item {

    border-bottom: 1px dashed var(--borderLineColor);
    padding: 16px 0;
    cursor: pointer;

}

.storehouse_item_index_name {
}

.storehouse_item_index {
    display: inline-block;
    background: #2F73E9;
    padding: 4px;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bold;
    transform: scale(0.82);
    color: #FFFFFF;
}

.storehouse_item_name {
    color: #FFFFFF;
    font-size: 16px;
    margin-left: 8px;
}

.storehouse_department_num {
    padding-left: 34px;
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}

.storehouse_img_address {
    display: flex;
    align-items: center;
}

.storehouse_img {
    width: 14px;
    height: 14px;
    transform: translateY(-1px) scale(0.86);
    /*margin-right: 8px;*/
}

.storehouse_address {
    max-width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    color: #A3A7CC;
    font-size: 12px;
    transform: scale(0.86);
    margin-left: -8px;
}

.storehouse_img_stock {
    display: flex;
    align-items: center;
}

.storehouse_stock {
    display: inline-block;
    color: #A3A7CC;
    font-size: 12px;
    transform: scale(0.86);
    margin-left: -8px;
    max-width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/*仓库列表结束*/

/*库存列表*/
.stock_list_box {
    height: 60%;
    width: 100%;
}

.stock_list_title {
    background-color: #161D6B;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 14px 28px;
}

.stock_list_title_name {
    width: 31%;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
}

.stock_list_title_type {
    width: 31%;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
}

.stock_list_title_num {
    width: 22%;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
}

.stock_list_item_box {
    height: 100%;
    overflow-y: scroll;
    padding: 0 22px 0 28px;
}

.stock_list_item {
    padding: 16px 0;
    border-bottom: 1px dashed var(--borderLineColor);
    display: flex;
    justify-content: space-between;
}

.stock_list_item_name {
    width: 120px;
    text-align: left;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #FFFFFF;
    font-size: 14px;
}

.stock_list_item_type {
    width: 120px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #FFFFFF;
    font-size: 14px;
}

.stock_list_item_num {
    width: 22%;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #00FEE1;
    font-size: 14px;
}
