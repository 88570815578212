.device_detail_page {
    width: 100%;
    height: 100vh;
    background-color: var(--themeBgColor);
}

.device_detail_main {
    padding: 16px 28px 0 28px;
}

.device_detail_box {
    width: 100%;
    height: 36.66667vh;
    background: url("../../../../asset/device/detail/device_detail_bg.png") left top/cover no-repeat;
    background-size: 100% 36.66667vh;
    padding: 24px 36px;
    display: flex;
    justify-content: space-between;
}

.device_detail_content {
    width: 32%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.device_detail_item {
    display: flex;
    align-items: start;
    margin-top: 16px;
}

.device_detail_item_label {
    color: #FFFFFF;
    font-size: 16px;
    display: flex;
    align-items: center;
    flex: 2;
}

.device_detail_item_icon {
    width: 22px;
    height: 22px;
    transform: translateY(-1px);
    margin-right: 10px;
}

.device_detail_item_value {
    color: #FFFFFF;
    font-size: 16px;
    transform: translateY(2px);
    flex: 8;
    height: 5vh;
    overflow-y: scroll;
}

.device_detail_log {
    height: 13vh;
    overflow-y: scroll;
}

.device_detail_descriptions {
    height: 9.25926vh;
    overflow-y: scroll;
}

.device_detail_item_file {
    height: 10vh;
    overflow-y: scroll;
}

.device_detail_step :global .ant-steps-item-container {
    height: 30px;
}

.device_detail_step :global .ant-steps-item-container .ant-steps-item-tail {
    top: 16px !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.device_detail_step :global .ant-steps-item-container .ant-steps-item-tail:after {
    background-color: #2EB1F1 !important;
}

.device_manufacturer_info {
    width: 100%;
    height: 9.62963vh;
    background: url("../../../../asset/device/detail/device_detail_manufacturer_bg.png") left top/cover no-repeat;
    background-size: 100% 9.62963vh;
    margin-top: 16px;
}


/*设备工单*/
.device_detail_workorder {
    margin-top: 16px;
}

.device_detail_workorder_title {
    background-color: #18275B;
    font-size: 18px;
    font-weight: bold;
    padding: 12px 24px;
    color: #FFFFFF;
}

.device_detail_workorder_table {
    padding: 8px 0 8px 16px;
}

.device_detail_table_title {
    background-color: #243978;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.device_detail_table_title_label {
    display: inline-block;
    color: #247CE1;
    font-weight: bold;
    font-size: 14px;
    width: 12%;
    text-align: center;
}

.device_detail_table_content {
    max-height: 30.5vh;
    overflow-y: scroll;
}

.device_detail_table_item {
    margin-top: 8px;
    padding: 10px 0 10px 10px;
    display: flex;
    justify-content: space-between;
    background-color: #243978;
}

.device_detail_table_item_label {
    color: #57C7ED;
    font-size: 12px;
    display: inline-block;
    width: 12%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
}
