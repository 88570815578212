p {
    margin: 0 !important;
}

.amap-logo {
    display: none !important;
}

.amap-marker-label {
    position: relative;
    border: none !important;
    margin: 0;
    padding: 0;
    background: url("../../../../../asset/infoWindowBg.png") left center/cover no-repeat;
    min-height: 432px;
    min-width: 433px;
    border-color: transparent;
}

/*点击弹出层样式 start*/
.amap-info-content {
    position: relative;
    border: none !important;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border-radius: 8px;
}

.bottom-center .amap-info-sharp {
    border-radius: 3px;
}

/*点击退出层样式 end*/

.text-style {
    color: #FFFFFF;
    font-size: 14px;
    padding: 12px 15px;
}

.engineer-box {
    max-width: 368px;
    background-color: rgba(15, 42, 165, 0.6);
}

.engineer-department {
    background: linear-gradient(to right, #2CADFF, #1F62C0, #1343A3);
    padding: 10px;
}

.department-name {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
}

.engineer-info {
    display: flex;
    padding: 15px;
}

.engineer-info img {
    width: 56px;
    height: 56px;
    margin-right: 14px;
    border-radius: 50%;
}

.engineer-name-mark {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.engineer-mark {
    color: #FFFFFF;
    font-size: 14px;
}

.engineer-name {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
}

.now-work {
    margin: 8px 0;
}

.today-work {
    padding-top: 0;
}

.customer-department, .customer-name, .address {
    padding: 8px 15px;
}

.customer-department {
    display: flex;
}

.detail {
    cursor: pointer;
    min-width: 369px;
    background-color: #2CADFF;
    text-align: center;
    padding: 10px 0;
    margin-top: 10px;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
}

.maker-box {
    position: relative;
}

.maker-name {
    position: absolute;
    top: -10px;
    left: 15px;
    color: #FFFFFF;
    font-weight: bold;
}

.department-maker-name {
    width: 120px;
    position: absolute;
    top: -10px;
    left: -6px;
    color: #FFFFFF;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.department-maker-logo {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.search-ipt {
    position: absolute;
    top: 100px;
    width: 30%;
}

#customer-department {
    display: inline-block;
    width: 100%;
    flex: 7;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


/*单位弹窗*/
.engineer_department_box {
    position: relative;
    width: 343px;
    height: auto;
    /*height: 21.29630vh;*/
    background: url("../../../../../asset/engineer/map_department_dialog_bg.png") top left/cover no-repeat;
    padding: 1.48148vh 0;
}

.engineer_department_title {
    height: 5.18519vh;
    display: flex;
    align-items: center;
    padding: 0 8px;
    margin: 8px 0;
}

.engineer_department_logo_box {
    padding: 8px;
    background-color: #0B0F3B;
    border-radius: 4px;
}

.engineer_department_logo {
    width: 44px;
    height: 4.2vh;
    border-radius: 50%;
    /*object-fit: contain;*/
}

.department_info_box {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.department_name_span {
    font-size: 1.48148vh;
    color: #FFFFFF;
    font-weight: bold;
}

.department_engineer_num {
    font-size: 1.11111vh;
    color: #FFFFFF;
    margin-top: 8px;
}

.storehouse_department_name {
    max-width: 250px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.department_engineer_icon {
    width: 14px;
    height: 1.29630vh;
    transform: translateY(-1px);
    margin-right: 8px;
}

.department_work_item_box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 8px;
}

.department_work_item {
    font-size: 1.48148vh;
    color: #FFFFFF;
    padding: 0.55556vh 0;
}

.engineer_department_detail {
    position: absolute;
    width: 100%;
    height: 3.5vh;
    line-height: 3.5vh;
    /*margin-top: 1.6vh;*/
    color: #FFFFFF;
    font-weight: bold;
    font-size: 1.11111vh;
    background-color: #2CADFF;
    text-align: center;
    border-radius: 4px;
    /*left: 0;*/
    bottom: 0;
    cursor: pointer;
}

.InfoWindow{
    /*background: url("../../../../asset/zhuanshuiBj.png") left top no-repeat;*/
    background: url("../../../../../asset/engineer/map_department_dialog_bg.png") top left/cover no-repeat;
    min-height: 100px;
    min-width: 350px;
    position: relative;
    overflow: inherit;
    padding-top: 7px;
}

.devicebox{
    min-width: 260px;
    border: 1px #2cadff solid;

}
.devicebox .header,.devicebox .footer{
    height: 50px;
}
.devicebox .header{
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 0 10px;
}
.devicebox .one{
    margin: 10px 0 0 10px;
}
.devicebox ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
.devicebox ul li{
    color: #ffffff;
    height: 30px;
    line-height: 30px;
    padding-left: 10px;
}
.devicebox .bottom{
    height: 30px;
    line-height: 30px;
    margin: 0 0 0 10px;
}
.devicebox .header .sb-logo{
    float: left;
    width: 50px;
    margin: 0;
}
.devicebox .header .sb-logo img{
    width: 100%;
    height: auto;
    display: block;
}
.devicebox .header .status{
    padding-left: 15px;
    line-height: 20px;
    float: left;
    color: #ffffff;
}

.devicebox .footer{
    height: 30px;
    display: flex;
    margin-top: 10px;
}
.devicebox .footer span{
    display: block;
    height: 30px;
    width: 50%;
    text-align: center;
    cursor: pointer;
    line-height: 30px;
    color: #ffffff;
}