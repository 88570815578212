.detail_view {
    background-color: var(--themeBgColor);
    width: 100%;
    height: 100vh;
}

.detail_view_content {
    display: flex;
    padding: 8px 30px;
}
