.facturertitle{
    height: 60px;
    width: 100%;
    background: #01165E;
    color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}
.logo{
    height: 70%;

}
.logotext{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 30px;
    color: #00F6FF;
    line-height: 96px;

}
.facturertitle .tolink{
    height: 60px;
    width: 60px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
}
.facturertitle .topLeft,.facturertitle .topRight,.facturertitle .bottomLeft,.facturertitle .bottomRight{
    display: block;
    width: 2px;
    height: 2px;
    background: #00F6FF;
    position: absolute;
}

.facturertitle .topLeft {
    top: 0;
    left: 0;
}
.facturertitle .topRight{
    top: 0;
    right: 0;
}
.facturertitle .bottomLeft {
    bottom: 0;
    left: 0;
}
.facturertitle .bottomRight{
    bottom: 0;
    right: 0;
}
