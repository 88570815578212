.child_device_list {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.child_device_list_item {
    width: 536px;
    height: 15.87037vh;
    padding: 0 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(8, 43, 255, 0.2);
    margin: 8px 0;
}

.child_device_list_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    cursor: pointer;
}

.device_department_customer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.device_list_value {
    font-size: 12px;
    color: #FFFFFF;
    width: 47%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    /*margin-top: 16px;*/
}

.detail {
    width: 100%;
    padding: 10px 0;
    cursor: pointer;
    text-align: center;
    color: #51FCF9;
    font-size: 12px;
    border-top: 1px dashed rgba(255, 255, 255, 0.5);
}
