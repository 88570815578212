.workorder_page {
    width: 100%;
    height: 100vh;
    background: url("../../../asset/workorder/workorder_page_bg.png") left top/cover no-repeat;
}

.workorder_page_content {
    padding: 16px 16px 0 16px;
}

.left_center_right {
    display: flex;
}
