.device_title {
    display: flex;
    justify-content: space-between;
}

.device_title_item_box {
    display: flex;
    align-items: center;
    /*width: 30%;*/
    /*transform: scale(0.5);*/
}

.device_num_img {
    width: 30px;
    height: 3.2vh;
}

.device_title_item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    margin-left: 8px;
}

.device_title_item_value {
    font-size: 18px;
    color: #FFFFFF;
    font-weight: bold;
}

.device_title_item_label {
    display: inline-block;
    font-size: 8px;
    color: #FFFFFF;
    transform: scale(0.65) translateX(-20px);
    /*white-space: nowrap;*/
}
