.text_num{
    color: #FFFFFF;
}
.text_num .title{
    font-size: 18px;
    color: #00BAFF;
    height: 40px;
    line-height: 40px;
    text-align: left;
    padding-left: 10px;
}
.text_num .num{
    font-size: 30px;
    height: 40px;
    line-height: 30px;
    font-weight:bold;
    color: #FFB400;
    text-align: left;
    padding-left: 10px;
}
.text_num .title .tag{
    color: #00BAFF;
    font-size: 12px;
    margin-left: 10px;
    padding: 1px 5px;
    border: 1px solid #00BAFF;
}