.customer_login_page {
    width: 100%;
    height: 100vh;
    background: url("../../asset/loginImg/customer/customer_login_bg.png") top left/cover no-repeat;
    background-size: 100% 100vh;
}

.customer_login_left {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.customer_login_right {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.customer_login_left_img {
    width: 819px;
    height: 75.27778vh;
}

.customer_login_right :global .ant-form {
    width: 564px;
    height: 48.98148vh;
    padding: 0 63px;
    background: url("../../asset/loginImg/customer/customer_login_form_bg.png") top left/cover no-repeat;
    background-size: 564px 48.98148vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/*.customer_login_right :global .ant-row {*/
/*    width: 430px;*/
/*}*/

/*.captcha_value :global .ant-row {*/
/*    width: 220px;*/
/*}*/

.verify_image {
    width: 160px;
    color: white;
    cursor: pointer;
}

.customer_login_right :global .css-dev-only-do-not-override-1me4733.ant-input-affix-wrapper {
    background-color: rgba(47, 115, 233, 0.15);
    border-color: #2F73E9;
    color: #0084FF;
}

.customer_login_right :global .css-dev-only-do-not-override-1me4733.ant-input-affix-wrapper > input.ant-input {
    background-color: transparent;
    color: white;
}

.customer_login_right :global .ant-input::-ms-input-placeholder {
    color: #c0c0c0;
}

.customer_login_right :global .ant-input::-webkit-input-placeholder {
    color: #c0c0c0;
}

.customer_login_right :global .css-dev-only-do-not-override-1me4733.ant-input-affix-wrapper .anticon.ant-input-password-icon {
    color: #0084FF;
}


.welcome_login {
    width: 100%;
    display: flex;
    font-size: 36px;
    color: #FFFFFF;
    justify-content: center;
    margin-bottom: 24px;
}
