.area_right_content {
    margin-top: 16px;
}

.department_list {
    padding: 27px 13px;
    width: 452px;
    height: 77.03704vh;
    background: url("../../../../../asset/area/area_department_bg.png") left top/cover no-repeat;
    background-size: 452px 77.03704vh;
}

.department_list_title {
    position: relative;
    background-color: #0D469C;
    padding: 8px 14px;
    color: #00FFE1;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
}

.department_list_title:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border: 6px solid #0B1449;
    border-bottom-color: transparent;
    border-left-color: transparent;
}

.department_list_content {
    height: 97%;
    overflow-y: scroll;
}

.department_item {
    padding: 18px 14px;
    border-bottom: 1px dashed #CED2FE;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.department_name {
    font-size: 16px;
    color: #CED2FE;
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.department_workorder {
    font-size: 16px;
    color: #FFFFFF;
    font-weight: bold;
}
