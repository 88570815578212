.engineer_center_view {
    margin-top: 4px;
}
.title{
    height: 20px;
    color: #29b3f4;
    font-size: 16px;
    border-left: 3px #29b3f4 solid;
    padding-left: 15px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.card_button_view{}
.card_button_view span{
    cursor: pointer;
    color: #29b3f4;
    border: 1px solid #29b3f4;
    padding: 0 4px;
    line-height: 30px;
    border-radius: 4px;
    margin-left: 8px;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
.card_button_view span:hover{
    color: #686daa;
    border: 1px solid #686daa;
}

.person_work_situation_view60{
    width: 100%;
    height: calc(100% - 200px);
}
.person_work_situation_view100{
    width: 100%;
    height: 100%;
}
.person_work_tongj_view{
    width: 100%;
}
.tongj_view{
    height: 110px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.tongj_view_item{
    border-radius: 0.26042vw;
    border: 2px #1084e9 solid;
    width: 30%;
    height: 100%;
    background: #131854;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.tongj_view_item_title{
    color: #29b3f4;
    text-align: center;
    font-size: 15px;
}
.tongj_view_item_num{
    color: #29b3f4;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
}
.mychart_bar{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 85%;
    align-items: center;
}
.mychart_bar_xAxis_item{
    font-size: 12px;
    color: #ffffff;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.mychart_bar_xAxis_item span{
    display: inline-block;
    width: calc(100% / 15);
}
.mychart_bar_yAxis{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 35px;
}
.mychart_bar_yAxis span{
    font-size: 12px;
    color: #ffffff;
    position: relative;
}
.mychart_bar_yAxis span:after{
    content: '';
    position: absolute;
    width: calc(100%*13);
    height: 1px;
    background: #24274f;
    top: 8px;
    left: 92%;
    z-index: 1;
}
.mychart_bar_item{
    display: flex;
    width: calc(100% / 15);
    height: 94%;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 5px;
    overflow: hidden;
    background: #dddddd0f;
    z-index: 2;
}
.mychart_bar_item span{
   display: block;
    width: 100%;
}
.mychart_bar_item span:hover{
    border: 2px solid #b91143;
    cursor: pointer;
}
/*指标*/
.index_card_view {
    width: 533px;
    display: flex;
    justify-content: space-between;
}

.the_receive_workorder {
    width: 167px;
    height: 9.62963vh;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(to right, #088FE7, #2EB8F6);
}

.the_handle_workorder {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 167px;
    height: 9.62963vh;
    background: linear-gradient(to right, #AB1CFF, #7B57E8);
}

.on_time_workorder {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 167px;
    height: 9.62963vh;
    background: linear-gradient(to right, #2968DA, #098BED);
}

.index_card_num {
    font-size: 36px;
    font-weight: bold;
    color: #FFFFFF;
}

.index_card_label {
    font-size: 14px;
    color: #FFFFFF;
}

/*指标结束*/

/*工作详情*/
.engineer_work_detail_view {
    position: relative;
    width: 533px;
    height: 45.29630vh;
    padding: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.engineer_work_detail_item {
    display: flex;
    align-items: center;
    border-bottom: 1px dashed rgba(230, 230, 230, 0.3);
    padding: 16px 0;
}

.engineer_work_item_img {
    width: 49px;
    height: 55px;
}

.engineer_work_detail_content {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
}

.engineer_work_detail_content_title {
    max-width: 400px;
    font-size: 20px;
    color: #FFFFFF;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.engineer_work_detail_content_value {
    color: #B2C1D6;
    font-size: 16px;
    margin-top: 8px;
}

/*工作详情结束*/

/*个人完成指标数*/
.person_work_finish_situation_view {
    /*margin-top: 30px;*/
    position: relative;
    /*width: 1358px;*/
    height: 302px;
}

.person_input_device_num {
}

.person_replace_label_num {
}

.person_knowledge_num {
}

.person_handle_related_device_workorder {
}

.person_related_device_workorder_num {
}

.person_migrate_device_num {
}

/*容器边角*/
.card_top_left_corner {
    z-index: 999999;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-bottom-color: transparent;
    border-right-color: transparent;
}

.card_top_right_corner {
    z-index: 999999;
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-bottom-color: transparent;
    border-left-color: transparent;
}

.card_bottom_left_corner {
    z-index: 999999;
    position: absolute;
    bottom: 0;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-top-color: transparent;
    border-right-color: transparent;
}

.card_bottom_right_corner {
    z-index: 999999;
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-top-color: transparent;
    border-left-color: transparent;
}
