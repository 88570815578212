.home_page {
    width: 100%;
    height: 100vh;
    background: url("../../../asset/home/home_bg.png") #0B0F3B center -200px no-repeat;
}

.card_title {
    padding: 17px 0;
    width: 100%;
    text-align: center;
}

.card_title_content {
    font-weight: bold;
    color: #00FFE1;
    font-size: 18px;
    margin: 0 8px;
}

.card_title_img {
    width: 29px;
    height: 14px;
}

.top_card_box {
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
}

.top_card {
    position: relative;
    width: 260px;
    height: 230px;
    border: 1px solid #0B234F;
    background: url("../../../asset/home/card_top_bg.png") top center no-repeat;
    cursor: pointer;
}

.top_content_div {
    height: 60%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.top_card_line {
    width: 2px;
    height: 60px;
    background-color: #00FFE1;
}

.top_card_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card_img {
    width: 39px;
    height: 38px;
}

.left_top_card_img {
    width: 60px;
    height: 60px;
}

.top_card_num {
    color: #FFFFFF;
    font-size: 23px;
    font-weight: bold;
    margin-top: 10px;
}

.top_card_content {
    color: #FFFFFF;
    transform: scale(0.66, 0.66);
    font-size: 8px;
    margin-top: 2px;
}

.other_card_box {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.card_top_move {
    transform: translateY(-50px);
}

.left_card_box {

}

.left_top_card_item {
    width: 100%;
    display: flex;
    align-items: center;
}

.left_top_card_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
}

.left_top_card_item_content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-left: 16px;
}

.left_top_card_num {
    font-size: 24px;
    font-family: ArialMT;
    font-weight: normal;
}

.left_top_card_item_describe {
    font-size: 14px;
    margin-top: 4px;
    transform: translateY(2px);
}

.card_map_img {
    width: 133px;
    cursor: pointer;
}

.ops_div_img {
    width: 52px;
    height: 52px;
}

.ops_num {
    font-weight: bold;
    color: #FFFFFF;
    font-size: 18px;
}

.ops_describe {
    color: #B6BBED;
    font-size: 14px;
    transform: translateY(2px);
}

.ops_line {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 8px;
    /*background: #D8E8FF;*/
    border-bottom: 1px dashed #CED2FE;
    opacity: 0.3;
}

.right_card_box {

}

.storehouse_div {
    padding: 0 14px;
}

.storehouse_num {
    /*display: flex;*/
}

.storehouse_num_title {
    font-size: 12px;
    color: #FFFFFF;
    border-left: 2px solid #0A51D3;
    padding: 0 12px;
}

.storehouse_num_content {
    font-size: 24px;
    color: #FFFFFF;
    margin-top: 20px;
}

.storehouse_num_content_item {
    padding: 8px 11px;
    background: url("../../../asset/home/storehouse_card_digit_img.png");
}

.storehouse_quota {
    margin-top: 18px;
}


.card_top_left_corner {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-bottom-color: transparent;
    border-right-color: transparent;
}

.card_top_right_corner {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-bottom-color: transparent;
    border-left-color: transparent;
}

.card_bottom_left_corner {
    position: absolute;
    bottom: 0;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-top-color: transparent;
    border-right-color: transparent;
}

.card_bottom_right_corner {
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-top-color: transparent;
    border-left-color: transparent;
}

/*hover 显示缩略图*/

.thumbnail {
    width: 30%;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -60%);
    display: none;
    transition: all 0.5s ease-in-out;
}

.device_card:hover ~ .device_thumbnail {
    display: block;
}

.work_card:hover ~ .work_thumbnail {
    display: block;
}

.area_card:hover ~ .area_thumbnail {
    display: block;
}

.storehouse_card:hover ~ .storehouse_thumbnail {
    display: block;
}

.engineer_card:hover ~ .engineer_thumbnail {
    display: block;
}
