
.text_num_pie_echarts_box{
    width: 100%;
    height:21vh;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.text_num_pie_echarts_box .text_num{
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.text_num_pie_echarts_box .pie_echarts{
    width: 100%;
    height: 100%;
    z-index: 99;

}
.text_num_pie_echarts_box .no_pie_echarts{
    width: 100%;
    height: 100%;
    z-index: 99;
    background: url("../../../../../asset/nocopy.png") no-repeat 50% center;
    background-size: 90px auto;
}
