.manu_facturer_page ,.fevice_type_page{
    width: 100%;
    height: 100vh;
    background-color: var(--themeBgColor);
    overflow: hidden;
}
.manu_facturer_box{
    height: calc(100vh - 8.43vh);

}
.fevice_type_box{
    height: calc(100vh - 9vh);
    padding-top: 30px;
    background: url("../../../asset/changline.png") no-repeat  10px 10px;
    background-size: 99% 4px;

}
.caseinit_left{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.caseinit_box{
    height: 140px;
}
.caseinit_box .caseinit_logo{
    width: 435px;
    height: 140px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.caseinit_box .caseinit_logo img{
    display: block;
    height: auto;
    width: 65%;
}
.caseinit_box .caseinit_grade,.caseinit_box .caseinit_fault{
   position: relative;
    width: 300px;
    height: 140px;
    background: url("../../../asset/gzline.png") no-repeat  left bottom;
    background-size: 6px 130px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.caseinit_box .caseinit_fault::before{
    position: absolute;
    top: 0;
    right: 0;
    width: 6px;
    content: "";
    display: block;
    height: 140px;
    background: url("../../../asset/gzline.png") no-repeat  right bottom;
    background-size: 6px 130px;
}
.caseinit_box .caseinit_grade img,.caseinit_box .caseinit_fault img{
    width: 80px;
    height: 68px;

}
.caseinit_box .caseinit_grade .text_num,.caseinit_box .caseinit_fault .text_num{
    width: 140px;
}

.text_num{
    color: #FFFFFF;
}

.text_num_box{
    height: 140px;
    padding-top: 27px;
}

/*.fevice_title{*/
/*    background: url("../../../asset/changline.png") no-repeat  10px 10px;*/
/*    height: 8vh;*/
/*    line-height: 9vh;*/
/*    padding: 0 30px;*/
/*    color: #FFFFFF;*/
/*    font-size: 28px;*/
/*    font-weight: bold;*/
/*}*/