.dialog_view {
    position: absolute;
    width: 200%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /*background-color: var(--maskLayerBg);*/
    z-index: 999;
}

.dialog_title {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
}

.question_dialog_title {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
    position: absolute;
    top: 140px;
    right: 580px;
}

.device_question_statistics_chart_box {
    position: absolute;
    right: 550px;
    top: 170px;
    width: 384px;
    height: 34.24074vh;
    background: url("../../../../../asset/device/device_question_statistics_bg.png") top left/cover no-repeat;
    background-size: 384px 34.24074vh;
    padding-bottom: 8px;

}

.device_question_statistics_chart {
    width: 100%;
    height: 100%;
}


.device_insert_manufacturer_box {
    position: absolute;
    right: -110px;
    top: 70px;
    width: 479px;
    height: 41.48148vh;
    background: url("../../../../../asset/jifenBj.png") top left/cover no-repeat;
    background-size: 479px 41.48148vh;
    padding: 8px 20px ;
}
.device_insert_manufacturer_box p{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 15px;
    color: #9898F3;
    line-height: 35px;
}
.device_insert_manufacturer_box img {
    width: 190px;
    height: 6vh;
}
