
.text_num_pie_echarts_box{
    width: 100%;
    height: calc(68vh - 43vh);
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.text_num_pie_echarts_box .text_num{
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.text_num_pie_echarts_box .pie_echarts{
    width: 65%;
    height: 100%;
    z-index: 99;
}
.text_num_pie_echarts_box .no_pie_echarts{
    width: 65%;
    height: 100%;
    z-index: 99;
    background: url("../../../../../asset/nocopy.png") no-repeat 40% center;
    background-size: 85px auto;
}

.titTag{
    background: url("../../../../../asset/biaoqianico.png") no-repeat right center;
    color: #FFFFFF;
    font-size: 14px;
    height: 25px;
    line-height: 25px;
    padding:0 10px 0 5px;
    text-align: right;
}
.titNum{
    font-size: 40px;
    font-weight: 400;
    color: #FFB400;
    height: 85px;
    line-height: 105px;
}
.tittip{
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}