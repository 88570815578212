.manu_facturer_item{
    height: calc(100vh - 8.43vh);
    padding-top: 25px;
    width: 90%;
    margin: 0 auto;
}
.text_num_box{
    width: 90%;
    margin: 0 auto;
}
.grade_fault_box{
    position: relative;
    width: 100%;
    height: 146px;
    margin: 0 auto;
    background: url("../../../../../asset/gzline.png") no-repeat;
}
.grade_fault_box::before{
    position: absolute;
    top: 0;
    right: 0;
    width: 6px;
    content: "";
    display: block;
    height: 146px;
    background: url("../../../../../asset/gzline.png") no-repeat;
}
.grade_fault_box .fault{
    background: url("../../../../../asset/gzline.png") no-repeat;
}
.grade_fault_box .grade,.grade_fault_box .fault{
    height: 146px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.grade_fault_box .grade img,.grade_fault_box .fault img{
    display: block;
}
.grade_fault_box .grade .text_num,.grade_fault_box .fault .text_num{

    width: 160px;

}