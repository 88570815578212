.department_right_box {
    /*90vh = 屏幕高度 - 页面头部高度  32px = 页面内 上下padding*/
    height: calc(90vh - 32px);
    width: 265px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*background-color: red;*/
}

.department_right_item {
    width: 100%;
    height: 10vh;
    background: url("../../../../../asset/department/department_workorder_finish_bg.png") left top/cover no-repeat;
    background-size: 100% 10vh;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/
    padding-left: 113px;
    padding-top: 15px;
}

.department_right_item_value {
    font-size: 32px;
    color: #FFFFFF;
    font-weight: bold;
    background: linear-gradient(180deg, #FFFFFF 0%, #0CABFD 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: translateY(-8px);
}

.department_right_item_label {
    font-size: 16px;
    color: #FFFFFF;
}


.department_right_item_eva {
    background: url("../../../../../asset/department/department_workorder_eva_bg.png") left top/cover no-repeat;
}

.department_right_item_dispatch {
    background: url("../../../../../asset/department/department_workorder_dispatch_bg.png") left top/cover no-repeat;
}

.department_right_item_receive {
    background: url("../../../../../asset/department/department_workorder_receive_bg.png") left top/cover no-repeat;
}

.department_right_item_the_finish {
    background: url("../../../../../asset/department/department_workorder_the_finish_bg.png") left top/cover no-repeat;
}

.department_right_item_the_eva {
    background: url("../../../../../asset/department/department_workorder_not_eva_bg.png") left top/cover no-repeat;
}

.department_right_item_timeout {
    background: url("../../../../../asset/department/department_workorder_timeout_bg.png") left top/cover no-repeat;
}
