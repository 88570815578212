.department_page {
    width: 100%;
    height: 100vh;
    background-color: var(--themeBgColor);
}

.department_content {
    display: flex;
    padding: 24px 19px 8px 19px;
}

.department_device_content {
    padding: 0 32px;
}

.department_name {
    height: 8vh;
    font-size: 30px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
}

/*设备指标*/
.department_device_data {
    height: 10vh;
    display: flex;
    justify-content: space-around;
}

.department_device_data_item {
    margin-right: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.department_device_data_item_img {
    width: 71px;
    height: 71px;
}

.department_device_data_detail {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
}

.department_device_value {
    color: #FFFFFF;
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 6px;
}

.department_device_label {
    color: #FFFFFF;
    font-size: 19px;
}

/*设备列表*/
.child_device_list {
    position: relative;
    height: 70vh;
    margin-top: 16px;
    padding: 12px 16px;
    border: 1px solid var(--borderLineColor);
}

.child_device_list_box {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.child_device_list_box:after {
    content: "";
    display: block;
    clear: both;
}

.child_device_list_item {
    cursor: pointer;
    width: 536px;
    height: 15.87037vh;
    padding: 0 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(8, 43, 255, 0.2);
    margin: 8px 34px;
    float: left;
}

.child_device_list_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.device_department_customer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.device_list_value {
    font-size: 12px;
    color: #FFFFFF;
    width: 47%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    /*margin-top: 16px;*/
}

.detail {
    width: 100%;
    padding: 10px 0;
    cursor: pointer;
    text-align: center;
    color: #51FCF9;
    font-size: 12px;
    border-top: 1px dashed rgba(255, 255, 255, 0.5);
}
