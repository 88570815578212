.engineer_dialog_mask_layer {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--maskLayerBg);
}

.engineer_dialog_box {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}
