.dialog_view {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--maskLayerBg);
    z-index: 11;
}

.dialog_title {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
    position: absolute;
    top: 140px;
    right: 460px;
}

.question_dialog_title {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
    position: absolute;
    top: 140px;
    right: 580px;
}

.device_question_statistics_chart_box {
    position: absolute;
    right: 550px;
    top: 170px;
    width: 384px;
    height: 34.24074vh;
    background: url("../../../../../asset/device/device_question_statistics_bg.png") top left/cover no-repeat;
    background-size: 384px 34.24074vh;
    padding-bottom: 8px;
}

.device_question_statistics_chart {
    width: 100%;
    height: 100%;
}


/*接入厂商*/
.device_insert_manufacturer_box {
    position: absolute;
    right: 410px;
    top: 170px;
    width: 479px;
    height: 41.48148vh;
    background: url("../../../../../asset/device/device_question_statistics_bg.png") top left/cover no-repeat;
    background-size: 479px 41.48148vh;
    padding-bottom: 8px;
    padding-right: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.device_insert_manufacturer_box img {
    width: 190px;
    height: 6vh;
}
