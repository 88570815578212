.text_num{
    color: #FFFFFF;
}
.text_num .title{
    font-size: 14px;
    color: #0096FF;
    height: 50px;
    line-height: 50px;
    text-align: center;
}
.text_num .num{
    font-size: 30px;
    height: 50px;
    line-height: 70px;
    color: #9BFAFF;
    text-align: center;
}