.engineer_detail_right_view {
    margin-left: 24px;
    margin-top: 4px;
}

.engineer_detail_statistics_view {
    position: relative;
    width: 804px;
    height: 25.11111vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.engineer_detail_statistics_finish_time {
    width: 127px;
    height: 180px;
    background: url("../../../../../../asset/engineer/detail/average_work_time_icon.png") no-repeat;
    background-size: 127px 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.engineer_detail_statistics_timeout_num {
    width: 127px;
    height: 180px;
    background: url("../../../../../../asset/engineer/detail/timeout_workorder_icon.png") no-repeat;
    background-size: 127px 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.engineer_detail_statistics_on_time {
    width: 127px;
    height: 180px;
    background: url("../../../../../../asset/engineer/detail/on_time_workorder_icon.png") no-repeat;
    background-size: 127px 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.engineer_detail_statistics_response_time {
    width: 127px;
    height: 180px;
    background: url("../../../../../../asset/engineer/detail/workorder_response_time_icon.png") no-repeat;
    background-size: 127px 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.engineer_detail_statistics_value {
    color: #76F9FC;
    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
}

.engineer_detail_statistics_label {
    font-weight: bold;
    color: #FFFFFF;
    font-size: 10px;
    transform: scale(0.83);
    margin-top: 4px;
}

/*工单满意度*/
.engineer_detail_right_satisfied_chart {
    position: relative;
    width: 804px;
    height: 29.27778vh;
    margin-top: 24px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 8px;
}

.engineer_satisfied_chart {
    width: 804px;
    height: 29.27778vh;
}

/*容器边角*/
.card_top_left_corner {
    z-index: 999999;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-bottom-color: transparent;
    border-right-color: transparent;
}

.card_top_right_corner {
    z-index: 99;
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-bottom-color: transparent;
    border-left-color: transparent;
}

.card_bottom_left_corner {
    z-index: 999999;
    position: absolute;
    bottom: 0;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-top-color: transparent;
    border-right-color: transparent;
}

.card_bottom_right_corner {
    z-index: 999999;
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #059E9F;
    border-top-color: transparent;
    border-left-color: transparent;
}
