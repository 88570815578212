.workorder_detail_page {
    width: 100%;
    height: 100vh;
    background-color: var(--themeBgColor);
}

.workorder_detail_main {
    padding: 16px 28px 0 28px;
}

.workorder_detail_box {
    width: 100%;
    height: 46vh;
    background: url("../../../../asset/device/detail/device_detail_bg.png") left top/cover no-repeat;
    background-size: 100% 46vh;
    padding: 24px 36px;
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
}

.workorder_detail_content {
    width: 32%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.device_manufacturer_info {
    width: 100%;
    height: 10vh;
    background: url("../../../../asset/device/detail/device_detail_manufacturer_bg.png") left top/cover no-repeat;
    background-size: 100% 10vh;
}

.workorder_detail_item {
    display: flex;
    align-items: start;
    margin-top: 16px;
}

.workorder_detail_item_label {
    color: #FFFFFF;
    font-size: 16px;
    display: flex;
    align-items: center;
    /*flex: 2;*/
}

.workorder_detail_item_icon {
    width: 22px;
    height: 22px;
    transform: translateY(-1px);
    margin-right: 10px;
}

.workorder_detail_item_value {
    color: #FFFFFF;
    font-size: 16px;
    transform: translateY(2px);
    flex: 8;
    height: 5vh;
    overflow-y: scroll;
    margin-left: 14px;
}

.workorder_detail_log {
    padding: 16px 8px;
    display: inline-block;
    width: 100%;
    height: 22vh;
    /*height: 33vh;*/
    overflow-y: scroll;
}

.workorder_detail_descriptions {
    height: 6vh;
    overflow-y: scroll;
}

.workorder_detail_item_file {
    height: 10vh;
    overflow-y: scroll;
}

/*.workorder_detail_step :global .ant-steps-item-container {*/
/*    height: 30px;*/
/*}*/

/*.workorder_detail_step :global .ant-steps-item-container .ant-steps-item-tail {*/
/*    top: 16px !important;*/
/*    padding-bottom: 0 !important;*/
/*    padding-top: 0 !important;*/
/*}*/

/*.workorder_detail_step :global .ant-steps-item-container .ant-steps-item-tail:after {*/
/*    background-color: #2EB1F1 !important;*/
/*}*/

.workorder_manufacturer_info {
    width: 100%;
    height: 9.62963vh;
    background: url("../../../../asset/device/detail/device_detail_manufacturer_bg.png") left top/cover no-repeat;
    background-size: 100% 9.62963vh;
    margin-top: 16px;
}


/*设备工单*/
.device_detail_workorder {
    margin-top: 32px;
}

.device_detail_workorder_title {
    background-color: #18275B;
    font-size: 18px;
    font-weight: bold;
    padding: 12px 24px;
    color: #FFFFFF;
}
