.department_left_content {
}


.workorder_monitor_box {
    position: relative;
    padding: 27px 8px 27px 13px;
    width: 450px;
    height: 45.96296vh;
    background-size: 452px 37.22222vh;
    border: 1px solid var(--borderLineColor);
}

.workorder_monitor_title {
    color: #00BDFF;
    font-size: 16px;
    font-weight: bold;
}

.workorder_monitor_list {
    position: relative;
    width: 100%;
    height: 95%;
    overflow-y: scroll;
}

.workorder_monitor_item {
    padding: 16px 0;
    border-bottom: 1px dashed #00BDFF;
    cursor: pointer;
    height: 10.18519vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.workorder_monitor_item_title {
    display: flex;
    justify-content: space-between;
}

.workorder_monitor_item_title_name {
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    max-width: 60%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.workorder_monitor_item_title_type {
    font-size: 12px;
    color: #FF0505;
}

.workorder_monitor_create_handle_name {
    display: flex;
    justify-content: space-between;
}

.workorder_monitor_create_name {
    display: inline-block;
    font-size: 12px;
    color: #CED2FE;
    max-width: 60%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.workorder_monitor_handle_name {
    display: inline-block;
    font-size: 12px;
    color: #CED2FE;
    max-width: 60%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.workorder_monitor_create_time {
    font-size: 12px;
    color: #CED2FE;
}

.moreWorkorder {
    transition: all 1s ease-in-out;
    /*margin-top: -10.18519vh;*/
}


/*统计图*/
.department_left_chart_box {
    position: relative;
    width: 450px;
    height: 39.48148vh;
    padding: 11px 13px;
    border: 1px solid var(--borderLineColor);
    margin-top: 16px;
}

.department_left_chart {
    width: 100%;
    height: 100%;
}

.department_left_chart_empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
