.department_center_box {
    width: 1110px;
    margin: 0 21px;
}

.page_title {
    font-size: 30px;
    color: #FFFFFF;
    font-weight: bold;
    height: 5.55556vh;
    display: flex;
    align-items: center;
    width: 100%;
}


/*工单指标*/
.department_workorder_data {
    padding-left: 16px;
    margin-top: 24px;
    height: 8.33333vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.department_workorder_data_item {
    width: 260px;
    height: 8.33333vh;
    padding-left: 21px;
    display: flex;
    align-items: center;
    background-color: rgba(202, 240, 255, 0.2);
    border-left: 2px solid #00B5FF;
}

.department_workorder_data_img {
    width: 60px;
    height: 60px;
}

.department_workorder_data_detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 8px;
}

.department_workorder_data_label {
    color: #FFFFFF;
    font-size: 14px;
    margin-top: 4px;
}

.department_workorder_data_value {
    color: #FFFFFF;
    font-size: 24px;
    margin-bottom: 4px;
}

.department_workorder_data_customer {
    background-color: rgba(249, 85, 255, 0.2);
    border-left: 2px solid #F955FF;
}

.department_workorder_data_engineer {
    background-color: rgba(44, 201, 88, 0.2);
    border-left: 2px solid #2CC958;
}

.department_workorder_data_response_time {
    background-color: rgba(255, 169, 57, 0.2);
    border-left: 2px solid #FFA939;
}

/*设备指标*/
.department_device_data {
    padding-left: 16px;
    margin-top: 18px;
    display: flex;
    height: 12.03704vh;
    align-items: center;
    justify-content: space-between;
}

.department_device_data_item {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.department_device_data_item_img {
    width: 71px;
    height: 71px;
}

.department_device_data_detail {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
}

.department_device_value {
    color: #FFFFFF;
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 6px;
}

.department_device_label {
    color: #FFFFFF;
    font-size: 19px;
}

/*单位子单位*/
.department_child_box {
    position: relative;
    width: 100%;
    height: 53.18519vh;
    border: 1px solid var(--borderLineColor);
    margin-top: 29px;
    padding: 10px;
}

.department_child_title {
    background-color: #243978;

    padding: 12px 0 12px 39px;
    color: #247CE1;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}

.department_child_name {
    position: relative;
    width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 39px;
}

.department_child_device_num {
    width: 20%;
    text-align: center;
}

.department_child_device_list {
    width: 15%;
    text-align: center;
    cursor: pointer;
}

.department_child_device_detail {
    width: 15%;
    text-align: center;
    cursor: pointer;
}

.department_child_content {
    width: 100%;
    height: 92%;
    overflow-y: scroll;
    /*隐藏滚动条*/
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
}

/*隐藏滚动条*/
.department_child_content::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}


.department_child_item {
    position: relative;
    background-color: rgba(36, 57, 120, 0.5);
    padding: 12px 0 12px 0;
    color: #57C7ED;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}

.department_child_item_parent {
    position: relative;
    display: flex;
    width: 100%;
}

.being_child {
    display: flex;
    flex-direction: column;
}

.department_child_item_plus {
    position: absolute;
    top: -4px;
    left: 20px;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
}
