.left_content_box {
    margin-top: 16px;
}

.workorder_monitor_box {
    /*position: relative;*/
    padding: 27px 8px 27px 13px;
    width: 452px;
    height: 37.22222vh;
    background: url("../../../../../asset/workorder/workorder_monitor.png") left top/cover no-repeat;
    background-size: 452px 37.22222vh;
    overflow: hidden;
}

.workorder_monitor_title {
    color: #00BDFF;
    font-size: 16px;
    font-weight: bold;
}

.hidden_box {
    width: 100%;
    height: 32vh;
    overflow: hidden;
}

.workorder_monitor_list {
    position: relative;
    width: 100%;
    /*height: 95%;*/
    /*height: 32vh;*/
    animation: move 45s infinite linear;
    /*overflow-y: scroll;*/
    overflow: hidden;
}

@keyframes move {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-176vh);
    }
}

.workorder_monitor_list:hover {
    animation-play-state: paused;
}

.workorder_monitor_item {
    padding: 16px 0;
    border-bottom: 1px dashed #00BDFF;
    cursor: pointer;
    height: 10.18519vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.workorder_monitor_item_title {
    display: flex;
    justify-content: space-between;
}

.workorder_monitor_item_title_name {
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    max-width: 60%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.workorder_monitor_item_title_type {
    font-size: 12px;
    color: #FF0505;
}

.workorder_monitor_create_handle_name {
    display: flex;
    justify-content: space-between;
}

.workorder_monitor_create_name {
    display: inline-block;
    font-size: 12px;
    color: #CED2FE;
    max-width: 60%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.workorder_monitor_handle_name {
    display: inline-block;
    font-size: 12px;
    color: #CED2FE;
    max-width: 60%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.workorder_monitor_create_time {
    font-size: 12px;
    color: #CED2FE;
}


/*服务质量*/
.workorder_service_box {
    width: 452px;
    height: 13.88889vh;
    background: url("../../../../../asset/workorder/workorder_service.png") left top/cover no-repeat;
    background-size: 452px 100%;
    padding: 31px 16px;
    margin-top: 16px;
}

.workorder_service_title {
    font-size: 16px;
    color: #00BDFF;
    font-weight: bold;
}

.workorder_service_content {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}

.workorder_service_item {
    display: flex;
    flex-direction: column;
}

.workorder_service_item_icon {
    width: 21px;
    height: 21px;
    transform: translateY(-2px);
    margin-right: 8px;
}

.workorder_service_item_value {
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
}

.workorder_service_item_label {
    font-size: 12px;
    color: #ACAFD8;
    margin-top: 8px;
}


/*工单满意度*/
.workorder_satisfied_box {
    width: 452px;
    height: 23.14815vh;
    padding: 27px 13px;
    background: url("../../../../../asset/workorder/workorder_satisfied.png") left top/cover no-repeat;
    background-size: 452px 100%;
    margin-top: 16px;
}

.workorder_satisfied_title {
    font-size: 16px;
    color: #00BDFF;
    font-weight: bold;
}

.workorder_satisfied_chart {
    width: 100%;
    height: 17vh;
}

.moreWorkorder {
    transition: all 1s ease-in-out;
    margin-top: -10.18519vh;
}
